//types
import { SelectStyle } from '@components/lib/module/input/select/interfaces/app/config/selectStyles';

//code
import { basicStyle } from '@components/lib/module/input/select/app/config/basicStyle';

//Custom select style config for single-select
export const singleStyle: SelectStyle = {
    ...basicStyle,
    singleValue: (styles, { data }) => ({
        ...styles,
        background: data.selectedColorHex || '#E3E6EA',
        color: data.textColorHex,
        borderRadius: '14px',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '10px',
        paddingRight: '10px'
    })
};
