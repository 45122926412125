//type
import { HomeConfig } from '@components/home/main/interfaces/Home';

//library
import React, { ReactElement } from 'react';

/**
 * Invalid Address modal dialog
 *
 * @param {HomeConfig} config - The config.
 * @returns {ReactElement} - The ui
 */
const InvalidAddressPopup: React.FC<HomeConfig> = (config: HomeConfig): ReactElement => {
    const { toggleInvalidAddressPopup, popup } = config.controller.useInvalidAddressPopupCtrl();
    const {
        template: {
            layout: { PopUp }
        }
    } = config.lib;
    const open = popup.invalidAddress;

    return (
        <PopUp
            data={{
                open,
                onClose: toggleInvalidAddressPopup(false),
                width: 'md:w-[358px]',
                backgroundColor: 'bg-white',
                padding: 'p-6',
                margin: 'mx-4'
            }}
            lib={config.lib}>
            <div className="w-full">
                {open && (
                    <>
                        <p className="mb-5 text-[14px] leading-[19.6px]">
                            The address you entered could not be found. Please check that you
                            selected a valid address or postal code from the drop-down list.
                        </p>
                        <div className="flex items-center justify-center">
                            <button
                                type="button"
                                onClick={toggleInvalidAddressPopup(false)}
                                aria-label="submit-button"
                                className="h-11 rounded-lg px-10 py-[13px]  bg-sunglow">
                                <div className="text-[14px] leading-[17.5px] text-contrast-blue font-semibold">
                                    OK
                                </div>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </PopUp>
    );
};

export default InvalidAddressPopup;
