//type
import { HomeConfig } from '@components/home/main/interfaces/Home';

//library
import React, { ReactElement } from 'react';
import Image from 'next/image';
import { BUILDING_TYPE } from '@components/lib/interfaces/config/graphql/API';

/**
 * Building type modal dialog
 *
 * @param {HomeConfig} config - The config.
 * @returns {ReactElement} - The ui
 */
const BuildingTypePopup: React.FC<HomeConfig> = (config: HomeConfig): ReactElement => {
    const { toggleBuildingTypePopup, handleSubmit, popup, quoteType } =
        config.controller.useBuildingTypePopupCtrl();
    const {
        template: {
            layout: { PopUp }
        },
        external: {
            Formik: { Formik, Form, Field, ErrorMessage },
            Yup
        }
    } = config.lib;

    const open = popup.buildingType;

    return (
        <PopUp
            data={{
                open,
                onClose: toggleBuildingTypePopup(false),
                width: 'md:w-[532px]',
                backgroundColor: 'bg-cosmic-latte',
                padding: 'p-6',
                margin: 'mx-4'
            }}
            lib={config.lib}>
            <div className="w-full">
                {open && quoteType && (
                    <Formik
                        initialValues={{
                            isContinueWithoutSubmission: false,
                            email: ''
                        }}
                        validationSchema={Yup.object({
                            isContinueWithoutSubmission: Yup.boolean(),
                            email: Yup.string().when('isContinueWithoutSubmission', {
                                is: (isContinueWithoutSubmission: boolean) =>
                                    isContinueWithoutSubmission,
                                then: () => Yup.string().notRequired(),
                                otherwise: () =>
                                    Yup.string()
                                        .email('It is not a valid email')
                                        .required('Email cannot be empty')
                            })
                        })}
                        onSubmit={handleSubmit}
                        enableReinitialize>
                        {({ setFieldValue, values }) => (
                            <Form aria-label="submit-email" className="flex-1 flex flex-col">
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        aria-label="close"
                                        className="flex items-center px-3 py-1"
                                        onClick={toggleBuildingTypePopup(false)}>
                                        <Image
                                            src={'/close.svg'}
                                            alt="Close"
                                            width={16}
                                            height={16}
                                        />
                                    </button>
                                </div>
                                <div className="flex w-full items-center justify-center mb-5">
                                    <Image
                                        src="/getsolar_logo.png"
                                        alt="GetSolar"
                                        width={80}
                                        height={80}
                                    />
                                </div>
                                {quoteType === BUILDING_TYPE.HDB_FLAT && (
                                    <>
                                        <p className="mb-4 text-[14px] leading-[19.6px]">
                                            Unfortunately, homeowners who live in a HDB flat will
                                            not be able to install solar panels on their roofs.
                                        </p>
                                        <p className="mb-4 text-[14px] leading-[19.6px]">
                                            However, you&#39;ll be pleased to know that HDB does
                                            have its Green Towns Programme to install 540 MWp of
                                            solar panels on Singapore HDB estates by 2030.
                                        </p>
                                        <p className="mb-4 text-[14px] leading-[19.6px]">
                                            Instead, know someone who may be interested? Leave your
                                            email address so we can send you a personalised
                                            simulator link that you can share with your friends!
                                        </p>
                                        <div className="mb-2">
                                            <Field
                                                aria-label="email"
                                                className="shadow appearance-none border rounded w-full h-11 p-3 font-normal"
                                                name="email"
                                                type="text"
                                                placeholder="Enter your email"
                                            />
                                            {!values.isContinueWithoutSubmission && (
                                                <div className="text-red-500 text-xs italic pl-2">
                                                    <ErrorMessage name="email" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="text-xs mb-5">
                                            *<u>Terms and conditions</u> apply
                                        </div>
                                        <div className="flex flex-col md:flex-row md:justify-between">
                                            <button
                                                type="submit"
                                                aria-label="continue-without-submission-button"
                                                className="w-full md:w-[214px] rounded-lg py-3 mb-5 md:mb-0 bg-sunglow"
                                                onClick={() => {
                                                    setFieldValue(
                                                        'isContinueWithoutSubmission',
                                                        true
                                                    );
                                                    setFieldValue('email', '');
                                                }}>
                                                <div className="text-[14px] leading-[19.6px] text-contrast-blue">
                                                    Continue Without Submission
                                                </div>
                                            </button>
                                            <button
                                                type="submit"
                                                aria-label="continue-button"
                                                className="w-full md:w-[214px] rounded-lg py-3 bg-sunglow"
                                                onClick={() => {
                                                    setFieldValue(
                                                        'isContinueWithoutSubmission',
                                                        false
                                                    );
                                                }}>
                                                <div className="text-[14px] leading-[19.6px] text-contrast-blue">
                                                    Submit and Continue
                                                </div>
                                            </button>
                                        </div>
                                    </>
                                )}

                                {quoteType === BUILDING_TYPE.CONDOMINIUM && (
                                    <>
                                        <p className="mb-4 text-[14px] leading-[19.6px]">
                                            Do you want to proceed? If you own a condominium
                                            penthouse unit or a strata landed home, you must obtain
                                            approval from your Management Corporation Strata Title
                                            (MCST) before installing solar.
                                        </p>
                                        <p className="mb-4 text-[14px] leading-[19.6px]">
                                            Do note also that selling excess solar energy to the
                                            grid is not possible under an MCST structure. If
                                            you&#39;re an MCST or managing agent, select the
                                            Commercial Property option instead.
                                        </p>
                                        <div className="flex flex-col md:flex-row md:justify-between">
                                            <button
                                                type="button"
                                                aria-label="close-popup-button"
                                                className="w-full md:w-[214px] rounded-lg py-3 mb-5 md:mb-0 bg-sunglow"
                                                onClick={toggleBuildingTypePopup(false)}>
                                                <div className="text-[14px] leading-[19.6px] text-contrast-blue">
                                                    Select A New Option
                                                </div>
                                            </button>
                                            <button
                                                type="submit"
                                                aria-label="continue-without-submission-button"
                                                className="w-full md:w-[214px] rounded-lg py-3 bg-sunglow"
                                                onClick={() => {
                                                    setFieldValue(
                                                        'isContinueWithoutSubmission',
                                                        true
                                                    );
                                                }}>
                                                <div className="text-[14px] leading-[19.6px] text-contrast-blue">
                                                    Continue Anyway
                                                </div>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </PopUp>
    );
};

export default BuildingTypePopup;
