//type
import { SelectConfig } from '@components/lib/module/input/select/interfaces/Select';

//library
import React, { ReactElement } from 'react';

/**
 * The select input.
 *
 * @param {SelectConfig} config - The config.
 * @returns {ReactElement} - The select input.
 */
const Main: React.FC<SelectConfig> = ({
    data: {
        options,
        field,
        label,
        placeholder,
        styles,
        id,
        isMulti = false,
        closeMenuOnSelect = true,
        isDisabled = false,
        isClearable = false,
        submitOnMenuClose = false
    },
    controller: { useMainCtrl },
    lib: {
        external: { Select }
    }
}: SelectConfig): ReactElement => {
    const { selected, handleChange, getStyle, submit, error } = useMainCtrl();

    return (
        <Select
            instanceId={'react-select'}
            aria-label={label}
            aria-invalid={error ? true : false}
            placeholder={placeholder}
            name={field}
            value={selected}
            classNames={{
                option: (option) => `select-option-${option.data.value}`,
                multiValue: (value) => `multi-value-${value.data.value}`,
                noOptionsMessage: () => 'no-option-message',
                clearIndicator: () => `${field}-clear-all`
            }}
            options={options}
            onChange={handleChange}
            styles={(styles && getStyle(styles)) || getStyle('DEFAULT')}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isMulti={isMulti}
            inputId={id}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            isDisabled={isDisabled}
            menuPlacement="auto"
            isOptionDisabled={(option) => option.disabled || false}
            onMenuClose={(submitOnMenuClose && submit) || undefined}
            maxMenuHeight={200}
        />
    );
};

export default Main;
