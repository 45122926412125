//types
import { SelectStyle } from '@components/lib/module/input/select/interfaces/app/config/selectStyles';

//code
import { basicStyle } from '@components/lib/module/input/select/app/config/basicStyle';

//Custom select style config for multi-select
export const multiStyle: SelectStyle = {
    ...basicStyle,
    control: (styles, { isDisabled }) => ({
        ...styles,
        border: '0px',
        borderRadius: '0px',
        boxShadow: 'none',
        overflowY: 'auto',
        marginBottom: '0px',
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'default' : 'pointer',
        minHeight: '10px',
        height: '36px',
        borderBottom: '1px solid transparent',
        alignItems: 'flex-start',
        ':hover': {
            borderBottom: '1px solid black'
        },
        '::-webkit-scrollbar': {
            width: '0px'
        }
    }),
    container: (styles) => ({
        ...styles,
        scrollbarColor: 'transparent'
    }),
    valueContainer: (styles) => ({
        ...styles,
        textAlign: 'right',
        padding: '0px',
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'relative'
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        alignSelf: 'flex-start',
        paddingTop: '5px'
    }),
    input: (styles) => ({
        ...styles,
        padding: '0px',
        textAlign: 'right',
        caretColor: 'transparent',
        cursor: 'pointer',
        backgroundColor: 'transparent'
    }),
    multiValue: (styles, { data }) => ({
        ...styles,
        background: data.selectedColorHex || '#E5E7EB',
        borderRadius: '24px',
        padding: '4px',
        paddingLeft: '5px',
        paddingRight: '5px',
        alignItems: 'baseline'
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.textColorHex || '#506281'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        padding: '0px',
        cursor: 'pointer',
        display: data.fixed ? 'none' : 'block',
        ':hover': {
            backgroundColor: 'transparent',
            color: 'red'
        }
    }),
    dropdownIndicator: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? '#A0AEC0' : '#506281',
        alignSelf: 'flex-start',
        padding: '0px',
        paddingTop: '4px'
    }),
    clearIndicator: (styles) => ({
        ...styles,
        alignSelf: 'flex-start',
        padding: '0px',
        paddingTop: '4px'
    }),
    menu: (styles) => ({
        ...styles,
        minWidth: '216px'
    }),
    placeholder: (styles) => ({
        ...styles,
        paddingTop: '8px',
        paddingRight: '3px'
    })
};
