//library
import React, { ReactElement } from 'react';

//type
import { HomeProps, HomeConfig } from '@components/home/main/interfaces/Home';

//controller
import useControllers from '@components/home/main/app/controllers/useControllers';

//code
import Main from '@components/home/main/app/view/Main';

//model
import homeModel from '@components/home/main/app/model/homeModel';

/**
 * Home form
 *
 * @param {HomeProps} props - The props
 * @returns {ReactElement} - Home
 */
const HomeForm: React.FC<HomeProps> = (props: HomeProps): ReactElement => {
    const config: HomeConfig = {
        ...props,
        controller: useControllers({
            ...props,
            model: { homeModel: homeModel(props) }
        })
    };

    return <Main {...config} />;
};

export default HomeForm;
