//type
import {
    UseControllersReturn,
    UseMainCtrlReturn
} from '@components/lib/module/input/select/interfaces/app/controllers/useControllers';
import { SelectProps, Style, Option } from '@components/lib/module/input/select/interfaces/Select';
import { SelectStyle } from '@components/lib/module/input/select/interfaces/app/config/selectStyles';
import { PropsValue, SingleValue, MultiValue } from 'react-select';

//code
import { basicStyle } from '@components/lib/module/input/select/app/config/basicStyle';
import { singleStyle } from '@components/lib/module/input/select/app/config/singleStyle';
import { multiStyle } from '@components/lib/module/input/select/app/config/multiStyle';
import { defaultStyle } from '@components/lib/module/input/select/app/config/defaultStyle';

/**
 * The controller.
 *
 * @param {SelectProps} props - The props
 * @returns {UseControllersReturn} - The controllers.
 */
const useControllers = ({
    data: { field, options, setFieldFn, onChosen, isMulti = false },
    lib: {
        external: { Formik }
    }
}: SelectProps): UseControllersReturn => {
    /**
     * The controller for Main module.
     *
     * @returns {UseMainCtrlReturn} - The select input functions.
     */
    const useMainCtrl = (): UseMainCtrlReturn => {
        const [, meta, helpers] = Formik.useField({
            name: field
        });
        const { value } = meta;
        const { setValue } = helpers;
        const { submitForm, dirty } = Formik.useFormikContext();

        const selected =
            (value?.value && value) ||
            options.filter((option) =>
                Array.isArray(value)
                    ? value.some((data) => data == option.value)
                    : value == option.value
            );

        /**
         * Sets the new formik value on react-select change.
         *
         * @param {PropsValue<Option>} option - The single or multi values
         */
        const onSelectChange = (option: PropsValue<Option>) => {
            if (onChosen) {
                onChosen((option as SingleValue<Option>)?.value);
            }

            if (isMulti) {
                const optionValue = (option as MultiValue<Option>).length
                    ? (option as MultiValue<Option>).map((option) => option.value)
                    : undefined;

                setValue(optionValue);
                return;
            }

            setValue((option as SingleValue<Option>)?.value ?? '');
        };

        /** Submit the form */
        const submit = () => {
            dirty && submitForm();
        };

        /**
         * Get the styles.
         *
         * @param {Style} type - Style type.
         * @returns {SelectStyle} - Style.
         */
        const getStyle = (type: Style): SelectStyle => {
            let style = basicStyle;

            switch (type) {
                case 'SINGLE':
                    style = singleStyle;
                    break;
                case 'MULTI':
                    style = multiStyle;
                    break;
                case 'DEFAULT':
                    style = defaultStyle;
                    break;
            }

            return style;
        };

        return {
            selected,
            error: meta.error,
            getStyle,
            handleChange: setFieldFn ? setFieldFn(setValue) : onSelectChange,
            submit
        };
    };

    return { useMainCtrl };
};

export default useControllers;
