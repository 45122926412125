//types
import { SelectProps, SelectConfig } from '@components/lib/module/input/select/interfaces/Select';

//library
import React, { ReactElement } from 'react';

//controller
import useControllers from '@components/lib/module/input/select/app/controllers/useControllers';

//code
import Main from '@components/lib/module/input/select/app/view/Main';

/**
 * The select input.
 *
 * @param {SelectProps} props - The props
 * @returns {ReactElement} - The select input.
 */
const Select: React.FC<SelectProps> = (props: SelectProps): ReactElement => {
    const config: SelectConfig = {
        ...props,
        controller: useControllers(props)
    };

    return <Main {...config} />;
};

export default Select;
