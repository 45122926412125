//type
import { SelectStyle } from '@components/lib/module/input/select/interfaces/app/config/selectStyles';

//Custom select style config
export const basicStyle: SelectStyle = {
    control: (styles, { isDisabled }) => ({
        ...styles,
        border: '0px',
        borderRadius: '0px',
        boxShadow: 'none',
        overflowY: 'auto',
        marginBottom: '0px',
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'default' : 'pointer',
        display: 'flex',
        alignItems: 'center',
        minHeight: '17px',
        borderBottom: '1px solid transparent',
        ':hover': {
            borderBottom: '1px solid black'
        }
    }),
    valueContainer: (styles) => ({
        ...styles,
        textAlign: 'right',
        paddingLeft: '0px',
        paddingRight: '0px',
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'relative'
    }),
    input: (styles) => ({
        ...styles,
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        caretColor: 'transparent',
        cursor: 'pointer'
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        width: '0px'
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        alignSelf: 'center'
    }),
    singleValue: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? '#A0AEC0' : '#506281',
        maxWidth: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }),
    multiValue: (styles) => ({
        ...styles,
        background: 'transparent'
    }),
    clearIndicator: (styles) => ({
        ...styles,
        alignSelf: 'flex-start'
    }),
    dropdownIndicator: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? '#A0AEC0' : '#506281',
        alignSelf: 'flex-start',
        padding: '0px'
    }),
    menu: (styles) => ({
        ...styles,
        zIndex: '50',
        minWidth: '176px'
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#A0AEC0'
    })
};
