//type
import { HomeConfig } from '@components/home/main/interfaces/Home';

//library
import React, { ReactElement } from 'react';
import Image from 'next/image';

//code
import MainForm from '@components/home/main/app/view/form/MainForm';

/**
 * Main page for home page
 *
 * @param {HomeConfig} config - The config.
 * @returns {ReactElement} - Return page.
 */
const Main: React.FC<HomeConfig> = (config: HomeConfig): ReactElement => {
    const {
        template: {
            layout: { Header }
        }
    } = config.lib;
    const { map } = config.controller.useMainCtrl();

    return (
        <>
            <Header lib={config.lib} />
            <div className="h-[calc(100vh-56px)] md:h-[calc(100vh-80px)] w-full relative overflow-hidden">
                <Image
                    src={`/${map}`}
                    alt="Map"
                    style={{ objectFit: 'cover', width: '100%' }}
                    fill
                    priority={true}
                />
                <div className="h-full w-full absolute z-50 top-0">
                    <MainForm {...config} />
                </div>
            </div>
        </>
    );
};

export default Main;
