//type
import {
    UseControllersReturn,
    UseMainCtrlReturn
} from '@components/home/search/interfaces/app/controllers/useControllers';
import { GeosuggestRef, LocationRef, SearchProps } from '@components/home/search/interfaces/Search';

//library
import { useRef } from 'react';

/**
 * The controller.
 *
 * @param {SearchProps} props - The props
 * @returns {UseControllersReturn} - The search controllers.
 */
const useControllers = ({
    data: { countryCode, field, onChange },
    lib: {
        external: { Formik }
    }
}: SearchProps): UseControllersReturn => {
    const ref = useRef<GeosuggestRef>(null);

    /**
     * The controller for Search module.
     *
     * @returns {UseMainCtrlReturn} - The search function.
     */
    const useMainCtrl = (): UseMainCtrlReturn => {
        const validate = (search: string): string | void => {
            if (!search) {
                return 'Required';
            }
        };

        const [, meta, helpers] = Formik.useField({
            name: field,
            validate
        });
        const { value } = meta;
        const { setValue } = helpers;

        /**
         * Update the value if suggest selected
         *
         * @param {LocationRef} suggest - The suggested location
         * @returns {void} - The function to handle if suggest selected.
         */
        const onSuggestSelect = (suggest?: LocationRef) => {
            if (!suggest) {
                if (ref && ref.current) {
                    ref.current.clear();
                }
                setValue('');

                if (onChange)
                    onChange({ lat: null, lng: null, address: null, address_components: [] });

                return;
            }

            setValue(suggest.label);

            if (onChange)
                onChange({
                    lat: suggest.location.lat,
                    lng: suggest.location.lng,
                    address: suggest.label,
                    address_components: suggest.gmaps && suggest.gmaps.address_components
                });
        };

        /**
         * Update the value if suggestion input change
         *
         * @param {string} input - The address
         * @returns {void} - The function to handle if suggestion input change
         */
        const onSuggestInputChange = (input: string) => {
            if (onChange)
                onChange({
                    lat: null,
                    lng: null,
                    address: input,
                    address_components: []
                });
        };

        return {
            countryCode,
            ref,
            value,
            error: meta.error,
            onSuggestSelect,
            onSuggestInputChange
        };
    };

    return { useMainCtrl };
};

export default useControllers;
