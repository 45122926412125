//type
import { Quote } from '@components/lib/interfaces/model/models/Quote';

//library
import { AppState } from '@components/store/store';

/**
 * This selector is used to get current quote type state
 *
 * @param {AppState} state - The current state
 * @returns {string | null} - The quote type state
 */
const selectedQuoteType = (state: AppState): Quote['type'] => state.simulator.quote.type;

export default selectedQuoteType;
