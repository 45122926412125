//type
import { HomeProps } from '@components/home/main/interfaces/Home';
import { HomeModelReturn } from '@components/home/main/interfaces/app/model/homeModel';
import {
    COUNTRY_CODE,
    EmailMutationVariables
} from '@components/lib/interfaces/config/graphql/API';

//code
import emailMutation from '@components/home/main/app/model/query/emailMutation';

/**
 * Home Model
 *
 * @param {HomeProps} props - The resolvers.
 * @returns {HomeModelReturn} - The model functions.
 */

const homeModel = ({
    lib: {
        external: { clientApi, router },
        model: {
            resolver: { formatCountryCodeRsl }
        }
    }
}: HomeProps): HomeModelReturn => {
    const countryCode = formatCountryCodeRsl(router.locale);

    /**
     * Send email data to slack for referral
     *
     * @param {string} email - The email.
     * @returns {Promise<string>} - The result.
     */
    const email = async (email: string): Promise<string> => {
        const input: EmailMutationVariables = {
            input: {
                email
            }
        };
        return await emailMutation(clientApi, input);
    };

    /**
     * Get map image
     *
     * @param {COUNTRY_CODE} country_code - The country code.
     * @returns {string} - The result.
     */
    const getMap = (country_code: COUNTRY_CODE): string => {
        const map = {
            [COUNTRY_CODE.SG]: 'map_sg.png',
            [COUNTRY_CODE.MY]: 'map_my.png'
        };

        return map[country_code];
    };

    return {
        email,
        getMap,
        countryCode
    };
};

export default homeModel;
