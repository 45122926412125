//type
import {
    EmailMutation,
    EmailMutationVariables
} from '@components/lib/interfaces/config/graphql/API';

//library
import { Client, GraphQLResult } from 'aws-amplify/api';
import { email } from '@components/lib/config/graphql/mutations';

/**
 * Send email data to slack for referral purpose
 *
 * @param {Client} clientApi - Amplify API.
 * @param {EmailMutationVariables} variables - The query variables.
 * @returns {Promise<GraphQLResult<EmailMutation>>} - The response
 */
const emailMutation = async (
    clientApi: Client,
    variables: EmailMutationVariables
): Promise<string> => {
    const response: GraphQLResult<EmailMutation> = (await clientApi.graphql({
        query: email,
        variables
    })) as GraphQLResult<EmailMutation>;

    return response.data.email || '';
};

export default emailMutation;
