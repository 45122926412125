//type
import { HomeProps } from '@components/home/main/interfaces/Home';

//library
import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/dist/client/link';
import * as Formik from 'formik';
import * as Yup from 'yup';
import Geosuggest from '@ubilabs/react-geosuggest';
import Select from 'react-select';
import { Popup } from 'reactjs-popup';
import { generateClient } from 'aws-amplify/api';
import mixpanel from 'mixpanel-browser';

//code
import Header from '@components/lib/template/layout/Header';
import PopUp from '@components/lib/template/layout/PopUp';
import SelectInput from '@components/lib/module/input/select/Select';
import Home from '@components/home/main/Home';

//helper
import useMixpanelEffect from '@components/lib/helper/useMixpanelEffect';

// resolver
import formatCountryCodeRsl from '@components/lib/model/resolver/formatCountryCodeRsl';

//list
import { listBuilding } from '@components/lib/list/building';

/**
 * The index.
 *
 * @returns {ReactElement} - The home page
 */
const Index: React.FC = (): ReactElement => {
    const router = useRouter();
    const clientApi = generateClient();

    const props: HomeProps = {
        lib: {
            model: {
                resolver: {
                    formatCountryCodeRsl
                }
            },
            template: {
                layout: {
                    Header,
                    PopUp
                }
            },
            module: {
                input: {
                    Select: SelectInput
                }
            },
            list: { listBuilding },
            helper: {
                useMixpanelEffect
            },
            external: {
                router,
                Link,
                Formik,
                Yup,
                Select,
                Geosuggest,
                Popup,
                clientApi: clientApi,
                mixpanel
            }
        }
    };

    return <Home {...props} />;
};

export default Index;
