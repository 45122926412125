//type
import { BUILDING_TYPE } from '@components/lib/interfaces/config/graphql/API';

//code
import { List, SelectOption } from '@components/lib/interfaces/index';

export const building: List<SelectOption<string>> = {
    [BUILDING_TYPE.LANDED_HOUSE]: {
        value: BUILDING_TYPE.LANDED_HOUSE,
        label: 'Landed house'
    },
    [BUILDING_TYPE.CONDOMINIUM]: {
        value: BUILDING_TYPE.CONDOMINIUM,
        label: 'Condominium/Strata'
    },
    [BUILDING_TYPE.HDB_FLAT]: {
        value: BUILDING_TYPE.HDB_FLAT,
        label: 'HDB flat'
    },
    [BUILDING_TYPE.RENTED_COMMERCIAL_PROPERTY]: {
        value: BUILDING_TYPE.RENTED_COMMERCIAL_PROPERTY,
        label: 'Rented commercial property'
    },
    [BUILDING_TYPE.OWNED_COMMERCIAL_PROPERTY]: {
        value: BUILDING_TYPE.OWNED_COMMERCIAL_PROPERTY,
        label: 'Owned commercial property'
    }
};

export const listBuilding: SelectOption<string>[] = Object.values(building);
