//type
import { SelectStyle } from '@components/lib/module/input/select/interfaces/app/config/selectStyles';

export const defaultStyle: SelectStyle = {
    control: (styles, state) => ({
        ...styles,
        height: '44px',
        borderRadius: '8px',
        border:
            state.selectProps['aria-invalid'] === true
                ? '1px solid #FF6262'
                : '1px solid rgba(52,89,149,0.25)',
        boxShadow: 'none',
        backgroundColor: state.selectProps['aria-invalid'] === true ? '#FFE0E0' : 'white'
    }),
    option: (styles, state) => ({
        ...styles,
        color: '#016FB9',
        fontSize: '16px',
        fontWeight: 400,
        paddingTop: '16px',
        paddingBottom: '17px',
        paddingLeft: '16px',
        backgroundColor: state.isSelected ? '#EFF5FE' : 'white',
        borderBottom: '1px solid rgba(52, 89, 149, 0.25)',
        ':last-child': {
            borderBottom: 'none' // Remove border for the last option
        }
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none'
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        color: '#016FB9',
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : ''
    }),
    menu: (styles) => ({
        ...styles,
        marginTop: '2px'
    }),
    menuList: (styles) => ({
        ...styles,
        '::-webkit-scrollbar': {
            width: '4px',
            height: 0
        },
        '::-webkit-scrollbar-track': {
            background: '#DEE4ED'
        },
        '::-webkit-scrollbar-thumb': {
            background: '#417AD6'
        }
    }),
    singleValue: (styles) => ({
        ...styles,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19.36px'
    })
};
