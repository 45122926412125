//types
import { SearchProps, SearchConfig } from '@components/home/search/interfaces/Search';

//library
import React, { ReactElement } from 'react';

//controller
import useControllers from '@components/home/search/app/controllers/useControllers';

//code
import Main from '@components/home/search/app/view/Main';

/**
 * The place autocomplete input.
 *
 * @param {SearchProps} props - The props
 * @returns {ReactElement} - The phone input.
 */
const Search: React.FC<SearchProps> = (props: SearchProps): ReactElement => {
    const config: SearchConfig = {
        ...props,
        controller: useControllers(props)
    };

    return <Main {...config} />;
};

export default Search;
